import React from "react"
import Seo from "../components/seo"
import asset from "../lib/asset"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <Seo title="Zoe Costa Rica" />
    <h1>
      <span style={{ color: "black" }}>Zoe</span> Costa Rica
    </h1>

    <p>
      <em>
        &quot;...la iglesia, la cual es su cuerpo, la plenitud de Aquel que todo
        lo llena en todo" ~ Efesios 1:23
      </em>
    </p>

    <hr />

    <h3 className="style2">¡Bienvenidos a Zoe!</h3>

    <p>
      Este sitio web fue creado en 2009 y fue mantenido regularmente hasta 2013
      durante el tiempo que viví y trabajé en Costa Rica con un pequeño grupo de
      creyentes. Después de regresar a los Estados Unidos en 2013, el Señor
      comenzó a despertarme y corregirme con respecto a varias verdades vitales,
      de tal manera que sentí que era correcto dejar de enseñar y escribir por
      varios años con el fin de dar tiempo y libertad al Señor para hacer estas
      verdades más reales en mi corazón antes de intentar enseñarlas a otros.
      Durante los últimos 10 años he publicado en Zoe Costa Rica sólo unas pocas
      enseñanzas, y varios libros de los primeros tiempos de{" "}
      <a href="https://bibliotecadelosamigos.org">la Sociedad de Amigos</a> que
      han sido sumamente impactantes para mí. Sólo recientemente (en 2023) he
      sentido más libertad para reanudar la enseñanza, y hacer disponibles
      algunas enseñanzas tanto en este sitio web, como en mi blog llamado{" "}
      <a href="https://hender.blog">La Senda Antigua.</a> Aunque creo que hay
      verdades importantes contenidas en las enseñanzas más antiguas de este
      sitio, creo firmemente que{" "}
      <a href="/2023mensajes">las enseñanzas posteriores</a> son mucho más
      útiles para todos aquellos que buscan sinceramente conocer y vivir en ese
      reino que es luz, vida y justicia. &mdash;Jason Henderson
    </p>
    <h3 className="style2">Ense&ntilde;anzas</h3>
    <p>
      A través del sitio usted va a encontrar numerosas enseñanzas gratis, unas
      en forma de texto que podrá leer desde la red o imprimir, y otras como
      archivos de audio que le permitirán escuchar los sermones y enseñanzas
      desde su computadora o en su reproductor de CD o MP3. Para DESCARGAR un
      mensaje, haga clic derecho en “descargar” enlace y seleccione “Guardar
      Destino como...” (“Save Target As...”) o “Guardar Enlace como...” (“Save
      Link As...”), dependiendo del navegador que esté usando.
    </p>
    <p className="Anouncement">
      <strong>***</strong> Para todos los que visitan frecuentemente nuestro
      sitio web, les agradecería que leyeran o escucharan estas enseñanzas.
      ¡Gracias! - Jason Henderson
    </p>
    <table
      className="sermon_table"
      width="646"
      border="0"
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        <tr>
          <td width="476">
            <strong>La Naturaleza de la Obra Consumada de Cristo</strong>
          </td>
          <td width="49">
            <div>
              <a
                href={asset("mensajes/18/Naturaleza_de_la_Obra_Consumada.mobi")}
              >
                kindle
              </a>
            </div>
          </td>
          <td width="40">
            <div>
              <a
                href={asset("mensajes/18/Naturaleza_de_la_Obra_Consumada.epub")}
              >
                epub
              </a>
            </div>
          </td>
          <td width="33">
            <div>
              <a
                href={asset("mensajes/18/Naturaleza_de_la_Obra_Consumada.pdf")}
              >
                pdf
              </a>
            </div>
          </td>
          <td width="48">
            <div>
              <a href={asset("mensajes/18/Obra_Consumada.mp3")}>audio</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      className="sermon_table"
      width="646"
      border="0"
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        <tr>
          <td width="476">
            <strong>La Cruz de Cada Día</strong>
          </td>
          <td width="49"></td>
          <td width="40"></td>
          <td width="33">
            <div>
              <a href="https://hender.blog/publicaciones/la-cruz-de-cada-dia">
                texto
              </a>
            </div>
          </td>
          <td width="48">
            <div>
              <a href="https://cdn.cosmicjs.com/b9d35da0-ce61-11ed-94ed-95257c20dd73-La-cruz-de-cada-dia.mp3">
                audio
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <p className="Anouncement">&nbsp;</p>
  </Layout>
)

export default IndexPage
